/**
 * フライトステータス
 */
export const FlightStatus = {
    /**
     * Empty
     */
    Empty: "Empty",
    /**
     * 往路配送物の集荷待ち
     */
    OBStandby: "OB-Standby",
    /**
     * 往路の前準備
     */
    OBBeforePreparation: "OB-BeforePreparation",
    /**
     * 往路プリパレーションチェック中
     */
    OBPreparation: "OB-InPreparation",
    /**
     * 往路BeforeTakeoffチェック中
     */
    OBBeforeTakeoff: "OB-InBeforeTakeoff",
    /**
     * 往路テイクオフ
     */
    OBTakeoff: "OB-Takeoff",
    /**
     * 往路上昇中
     */
    OBInFlightClimb: "OB-InFlightClimb",
    /**
     * 往路飛行中クルーズ
     */
    OBInFlightCruise: "OB-InFlightCruise",
    /**
     * 往路飛行中
     */
    OBInFlightDescent: "OB-InFlightDescent",
    /**
     * 往路到着
     */
    OBAfterLanding: "OB-AfterLanding",
    /**
     * 復路フライト準備中
     */
    RTStandby: "RT-Standby",
    /**
     * 復路フライト準備中
     */
    RTBeforePreparation: "RT-BeforePreparation",
    /**
     * 復路フライト準備中
     */
    RTPreparation: "RT-InPreparation",
    /**
     * 復路フライト準備中
     */
    RTBeforeTakeoff: "RT-InBeforeTakeoff",
    /**
     * 復路テイクオフ
     */
    RTTakeoff: "RT-Takeoff",
    /**
     * 復路上昇中
     */
    RTInFlightClimb: "RT-InFlightClimb",
    /**
     * 復路飛行中クルーズ
     */
    RTInFlightCruise: "RT-InFlightCruise",
    /**
     * 復路飛行中
     */
    RTInFlightDescent: "RT-InFlightDescent",
    /**
     * 復路到着
     */
    RTAfterLanding: "RT-AfterLanding",
    /**
     * 完了
     */
    Completed: "Completed",
    /**
     * リセット（また引き返してまた最初から飛ぶ場合など）
     */
    Reset: "Reset",
    /**
     * イレギュラーが発生した場合
     */
    Irregular: "Irregular",
    /**
     * キャンセル
     */
    Canceled: "Canceled",
} as const;
export type FlightStatus = typeof FlightStatus[keyof typeof FlightStatus];

export const flightStatusForCheckList = [
    "BeforePreparation",
    "Preparation",
    "BeforeTakeoff",
    "Takeoff",
    "InFlightClimb",
    "InFlightCruise",
    "InFlightDescent",
    "AfterLanding",
] as const;
export type FlightStatusForCheckList = typeof flightStatusForCheckList[number];

/**
 * あるフライトステータスからstep分だけ移動したフライトステータスを取得する
 * @param status 
 * @param step 
 */
export function getNextFlightStatus(status: FlightStatus, step: number): FlightStatus {
    //ステッパーに対応していないステータスの場合は動かさない
    if (status === FlightStatus.Reset ||
        status === FlightStatus.Canceled ||
        status === FlightStatus.Empty ||
        status === FlightStatus.Irregular) {
        throw new Error("対応していないステータス");
    }

    const statusList = [
        FlightStatus.OBStandby,
        FlightStatus.OBBeforePreparation,
        FlightStatus.OBPreparation,
        FlightStatus.OBBeforeTakeoff,
        FlightStatus.OBTakeoff,
        FlightStatus.OBInFlightClimb,
        FlightStatus.OBInFlightCruise,
        FlightStatus.OBInFlightDescent,
        FlightStatus.OBAfterLanding,
        FlightStatus.RTTakeoff,
        FlightStatus.RTInFlightClimb,
        FlightStatus.RTInFlightCruise,
        FlightStatus.RTInFlightDescent,
        FlightStatus.RTAfterLanding,
        FlightStatus.Completed] as FlightStatus[];
    let targetIndex = statusList.findIndex((v, i) => v === status) + step;
    if (targetIndex > statusList.length || targetIndex < 0) {
        throw new Error("Index out of bounds:" + targetIndex);
    }
    return statusList[targetIndex];
}

export function isOutbound(status: FlightStatus): boolean {
    if (status === FlightStatus.OBStandby ||
        status === FlightStatus.OBBeforePreparation ||
        status === FlightStatus.OBPreparation ||
        status === FlightStatus.OBBeforeTakeoff ||
        status === FlightStatus.OBTakeoff ||
        status === FlightStatus.OBInFlightClimb ||
        status === FlightStatus.OBInFlightCruise ||
        status === FlightStatus.OBInFlightDescent ||
        status === FlightStatus.OBAfterLanding) {
        return true;
    } else {
        return false;
    }
}

export function isReturnTrip(status: FlightStatus): boolean {
    if (status === FlightStatus.RTStandby ||
        status === FlightStatus.RTBeforePreparation ||
        status === FlightStatus.RTPreparation ||
        status === FlightStatus.RTBeforeTakeoff ||
        status === FlightStatus.RTTakeoff ||
        status === FlightStatus.RTInFlightClimb ||
        status === FlightStatus.RTInFlightCruise ||
        status === FlightStatus.RTInFlightDescent ||
        status === FlightStatus.RTAfterLanding ||
        status === FlightStatus.Completed) {
        return true;
    } else {
        return false;
    }
}