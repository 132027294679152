import { Button, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import jsQR from 'jsqr';
import React, { useEffect, useRef } from 'react';

type ScanQrCodeProps = {
    setScan: React.Dispatch<React.SetStateAction<boolean>>
    isScan: boolean
    setQrCodeErrorList: React.Dispatch<React.SetStateAction<string[]>>
    setQrCodeData: React.Dispatch<React.SetStateAction<string | undefined>>
    setSejQrCodeJsonData: React.Dispatch<React.SetStateAction<sejQRCodeData | undefined>>
    setQrCodeJsonData: React.Dispatch<React.SetStateAction<object | undefined>>
    setDisplayQrCodeScanResult: React.Dispatch<React.SetStateAction<string | undefined>>
};

export type sejQRCodeData = {
    Port: string,
    Name: string,
    Time: string
}

const useStyles = makeStyles()((theme) => ({
    qrReader: {
        marginTop: theme.spacing(2),
        backgroundColor: "silver",
        width: "100%",
    },
}));

/**
 * QRコードをスキャンし、表示させる
 * @param props 
 * @returns 
 */
export const ScanQrCode: React.FC<ScanQrCodeProps> = (props) => {
    const canvasRef = useRef(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const { classes } = useStyles();

    useEffect(() => {
        const canvas: any = canvasRef.current;
        const video: any = videoRef.current;
        const context = canvas.getContext('2d');
        let qrCodeData: string | undefined = undefined;
        let qrCodeJsonData: object | undefined = undefined;

        navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then((stream) => {
            video.srcObject = stream;
            video.setAttribute("playsinline", true);
            video.play();
            startTick();
        }).catch((err) => {
            console.error("video error:" + err);
            props.setQrCodeErrorList(["カメラが使用できません"]);
            props.setScan(false);
        });

        const startTick = () => {
            setTimeout(startTick, 250);
            if (context && canvas) {
                if (video.readyState === video.HAVE_ENOUGH_DATA) {
                    canvas.height = video.videoHeight;
                    canvas.width = video.videoWidth;
                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                    let img = context.getImageData(0, 0, canvas.width, canvas.height);
                    console.log(img);

                    // QRコード解析
                    let code = jsQR(img.data, img.width, img.height, { inversionAttempts: "dontInvert" });
                    console.log(code);
                    if (code) {
                        // QRコード解析に成功した場合

                        // カメラ停止
                        props.setScan(false);
                        video.pause();
                        const stream = video.srcObject;
                        stream.getTracks().forEach(function (track: MediaStreamTrack) { track.stop(); });
                        video.srcObject = null;

                        if (code.data) {
                            qrCodeData = code.data;
                        } else {
                            // Shift_JISの文字列が存在する場合
                            const binaryData = code.binaryData;
                            const text_decoder = new TextDecoder("shift-jis");
                            const shiftJisData = text_decoder.decode(Uint8Array.from(binaryData).buffer);
                            qrCodeData = shiftJisData;
                        };

                        if (qrCodeData) {
                            props.setDisplayQrCodeScanResult(undefined);
                            try {
                                // JSON文字列をデコードする
                                qrCodeJsonData = JSON.parse(qrCodeData);
                                console.log(JSON.stringify(qrCodeJsonData, null, 2));
                                if (isSejQRCodeDataType(qrCodeJsonData)) {
                                    props.setSejQrCodeJsonData(qrCodeJsonData);
                                } else {
                                    props.setQrCodeJsonData(qrCodeJsonData);
                                };

                                props.setDisplayQrCodeScanResult(JSON.stringify(qrCodeJsonData));
                            } catch (error) {
                                console.error(JSON.stringify(error));
                                props.setQrCodeErrorList(["JSON文字列のデコードに失敗しました"]);
                                props.setQrCodeData(qrCodeData);
                                props.setDisplayQrCodeScanResult(qrCodeData);
                            };
                        };
                    };
                };
            };
        };
    }, []);

    /**
     * sejQRCodeData型判定
     * @param data 
     */
    const isSejQRCodeDataType = (data: any): data is sejQRCodeData => {
        return typeof data === "object" &&
            data !== null &&
            typeof (data as sejQRCodeData).Name === "string" &&
            typeof (data as sejQRCodeData).Port === "string" &&
            typeof (data as sejQRCodeData).Time === "string";
    };

    /**
    * カメラ停止ボタンを押した時
    */
    const handleTurnOffCameraButton = () => {
        props.setScan(false);
        const video: any = videoRef.current;
        video.pause();
        const stream = video.srcObject;
        if (stream) {
            stream.getTracks().forEach(function (track: MediaStreamTrack) { track.stop(); });
            video.srcObject = null;
        };
    };

    return (
        <React.Fragment>
            <Grid item xs={12} sm={12}>
                <Button
                    startIcon={<PhotoCamera />}
                    variant="outlined"
                    component="span"
                    onClick={handleTurnOffCameraButton}
                >
                    カメラを停止
                </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
                <video
                    autoPlay
                    playsInline={true}
                    ref={videoRef}
                    className={classes.qrReader}
                >
                    <canvas ref={canvasRef} width={"100%"} height={"100%"} />
                </video>
            </Grid>
        </React.Fragment>
    )
};