import React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, FormControl } from '@mui/material';
import { Link } from "react-router-dom"
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import HomeIcon from '@mui/icons-material/Home';
import ApplicationTooBar from '../../components/molecule/ApplicationToolBar';

const useStyles = makeStyles()((theme) => ({
    griditem: {
    },

    table: {
        minWidth: 100,
    },
    paper: {
        padding: '12px',
        textAlign: 'center',
        height: '100%',
    },
    confirmNum: {
        textAlign: 'center',
        height: '100%',
        width: '300px'
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
        backgroundColor: blue[500],
        color: 'white',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 32,
    },
    link: {
        textDecoration: 'none',
    },

    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },

}));




export default function OrderAuth() {
    const { classes } = useStyles();
    // const location = useLocation<{ order: OrderInfo }>();
    // const order = location.state.order as OrderInfo;



    return (
        <div>
            <Grid container justifyContent="center" spacing={1}>
                <Grid item xs={12} sm={12} >
                    <ApplicationTooBar label="受け取り確認" />
                </Grid>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Alert severity="success">受け取り確認が完了しました。 </Alert>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <div>
                        <Typography className={classes.title} variant="h6" component="div">
                            確認番号
                        </Typography>
                        <Paper className={classes.paper} elevation={0}>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sm={12} className={classes.griditem}>
                                    <Typography variant="h5" component="div" color="secondary">
                                        XYT08A0B
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>
                </Grid>
                <Typography className={classes.title} variant="h6" component="div">
                    荷物の受取人
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Paper className={classes.paper} elevation={0}>
                        <Typography variant="h5" component="div">
                            ソラノ タロウ様
                        </Typography>
                        <Typography variant="body1" component="div">
                            〒105-7108
                        </Typography>
                        <Typography variant="body1" component="div">
                            東京都港区東新橋１丁目５−２
                        </Typography>
                        <Typography variant="body1" component="div">
                            電話番号：09012345678
                        </Typography>
                    </Paper>
                </Grid>


                <Typography className={classes.title} variant="h6" component="div">
                    配送物
                </Typography>
                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <TableContainer component={Paper} elevation={0}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>商品名</TableCell>
                                    <TableCell align="right">重量&nbsp;(g)</TableCell>
                                    <TableCell align="right">個数</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        インシュリン
                                    </TableCell>
                                    <TableCell align="right">90g</TableCell>
                                    <TableCell align="right">5</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={12} className={classes.griditem}>
                    <Link className={classes.link} to="/orderhome">
                        <Paper className={classes.paper} elevation={0}>
                            <FormControl variant="standard" className={classes.formControl}>
                                <IconButton edge="start" color="primary" aria-label="menu" size="large">
                                    <HomeIcon /><Typography>ホームに戻る</Typography>
                                </IconButton>
                            </FormControl>
                        </Paper>
                    </Link>
                </Grid>

            </Grid>
        </div >
    );
}