/**
 * FD情報
 */
export interface EnvSensorInfo {
    /**
     * 環境センサー個体番号
     */
    deviceID: string,
    /**
     * 情報ソース
     */
    source: string,
    /**
     * タイムスタンプ(送信時にNTPサーバーから取得した時刻)
     */
    timestamp: string,
    /**
     * 風速[m/s]
     */
    wind_vel: number,
    /**
     * 風向[deg]
     */
    wind_dir: number,
    /**
     * 最大風速[m/s]
     */
    wind_maxvel: number,
    /**
     * 最大風速時の風向[deg]
     */
    wind_maxdir: number,
    /**
     * 最大瞬間風速[m/s]
     */
    wind_maxinst_vel: number,
    /**
     * 最大瞬間風速時の風向[deg]
     */
    wind_maxinst_dir: number,
    /**
     * 温度[℃]
     */
    temp: number,
    /**
     * 湿度[%]
     */
    hum: number,
    /**
     * 照度[lx]
     */
    lumi: number,
    /**
     * 気圧[hPa]
     */
    pres: number,
    /**
     * レインレベル 254段階
     */
    rain: number,
    /**
     * エラーフラグ(1バイト16進数)
     */
    errorFlag: string,
}


/**
 * 環境センサー
 */
export const EnvSensorKind = {
    /**
     * 環境センサーのソースがミネビアミツミの場合
     */
    Mitsumi: "Mitsumi"
} as const;