/**
 * 権限用グループ
 */
export const PrivilegeGroup = {
    GroupAdministrator: "group-管理者グループ",
    GroupSPFD: "group-SPFDグループ",
    GroupSPSupporter: "group-SPサポーターグループ",
    GroupLocalFD: "group-ローカルFDグループ",
    GroupLocalSupporter: "group-ローカルサポーターグループ",
} as const;
export type PrivilegeGroup = typeof PrivilegeGroup[keyof typeof PrivilegeGroup];

/**
 * 権限用グループdipslaySubId
 */
export const PrivilegeGroupDipslaySubId = {
    GroupAdministratorDipslaySubId: PrivilegeGroup.GroupAdministrator.replace("group-", ""),
    GroupSPFDDipslaySubId: PrivilegeGroup.GroupSPFD.replace("group-", ""),
    GroupSPSupporterDipslaySubId: PrivilegeGroup.GroupSPSupporter.replace("group-", ""),
    GroupLocalFDDipslaySubId: PrivilegeGroup.GroupLocalFD.replace("group-", ""),
    GroupLocalSupporterDipslaySubId: PrivilegeGroup.GroupLocalSupporter.replace("group-", ""),
} as const;
export type PrivilegeGroupDipslaySubId = typeof PrivilegeGroupDipslaySubId[keyof typeof PrivilegeGroupDipslaySubId];

/**
 * 権限用ロール
 */
export const PrivilegeRole = {
    RoleAdministrator: "role-管理者ロール",
    RoleFD: "role-FDロール",
    RoleSupporter: "role-サポーターロール",
    RoleUserManagement: "role-ユーザー管理ロール",
    RoleUserBrowsing: "role-ユーザー閲覧ロール",
    RoleAllDataVisible: "role-全データ閲覧可能ロール",
    RoleOwnPartnerIdDataVisible: "role-自パートナーIDデータ閲覧可能ロール",
} as const;
export type PrivilegeRole = typeof PrivilegeRole[keyof typeof PrivilegeRole];

/**
 * 権限用ロールdisplaySubId
 */
export const PrivilegeRoleDisplaySubId = {
    RoleAdministratorDisplaySubId: PrivilegeRole.RoleAdministrator.replace("role-", ""),
    RoleFDDisplaySubId: PrivilegeRole.RoleFD.replace("role-", ""),
    RoleSupporterDisplaySubId: PrivilegeRole.RoleSupporter.replace("role-", ""),
    RoleUserManagementDisplaySubId: PrivilegeRole.RoleUserManagement.replace("role-", ""),
    RoleUserBrowsingDisplaySubId: PrivilegeRole.RoleUserBrowsing.replace("role-", ""),
    RoleAllDataVisibleDisplaySubId: PrivilegeRole.RoleAllDataVisible.replace("role-", ""),
    RoleOwnPartnerIdDataVisibleDisplaySubId: PrivilegeRole.RoleOwnPartnerIdDataVisible.replace("role-", ""),
} as const;
export type PrivilegeRoleDisplaySubId = typeof PrivilegeRoleDisplaySubId[keyof typeof PrivilegeRoleDisplaySubId];

/**
 * 機能用権限
 */
export const FunctionPrivilege = {
    PrivilegeFunctionChangeFlightStatus: "privilege-function-フライトステータス変更",
    PrivilegeFunctionCreateFlight: "privilege-function-フライト作成",
    PrivilegeFunctionChangeStaStdEtaEtd: "privilege-function-STA・STD/ETA・ETD変更",
    PrivilegeFunctionLinkOrDeleteOrder: "privilege-function-配送依頼紐付・削除",
    PrivilegeFunctionEditPort: "privilege-function-ポート編集",
    PrivilegeFunctionUpdateUserInfo: "privilege-function-ユーザー情報更新",
    PrivilegeFunctionDisplayPrivilegeList: "privilege-function-権限一覧表示"
} as const;
export type FunctionPrivilege = typeof FunctionPrivilege[keyof typeof FunctionPrivilege];

/**
 * 機能用権限displaySubId
 */
export const FunctionPrivilegeDisplaySubId = {
    PrivilegeFunctionDisplaySubIdChangeFlightStatus: FunctionPrivilege.PrivilegeFunctionChangeFlightStatus.replace("privilege-function-", ""),
    PrivilegeFunctionDisplaySubIdCreateFlight: FunctionPrivilege.PrivilegeFunctionCreateFlight.replace("privilege-function-", ""),
    PrivilegeFunctionDisplaySubIdChangeStaStdEtaEtd: FunctionPrivilege.PrivilegeFunctionChangeStaStdEtaEtd.replace("privilege-function-", ""),
    PrivilegeFunctionDisplaySubIdLinkOrDeleteOrder: FunctionPrivilege.PrivilegeFunctionLinkOrDeleteOrder.replace("privilege-function-", ""),
    PrivilegeFunctionDisplaySubIdEditPort: FunctionPrivilege.PrivilegeFunctionEditPort.replace("privilege-function-", ""),
    PrivilegeFunctionDisplaySubIdUpdateUserInfo: FunctionPrivilege.PrivilegeFunctionUpdateUserInfo.replace("privilege-function-", ""),
    PrivilegeFunctionDisplaySubIdDisplayPrivilegeList: FunctionPrivilege.PrivilegeFunctionDisplayPrivilegeList.replace("privilege-function-", "")
} as const;
export type FunctionPrivilegeDisplaySubId = typeof FunctionPrivilegeDisplaySubId[keyof typeof FunctionPrivilegeDisplaySubId];

/**
 * データ用権限
 */
export const DataPrivilege = {
    PrivilegeDataAllPartnerId: "privilege-data-全てのパートナーID",
    PrivilegeDataOwnPartnerId: "privilege-data-所属するパートナーID"
} as const;
export type DataPrivilege = typeof DataPrivilege[keyof typeof DataPrivilege];

/**
 * データ用権限displaySubId
 */
export const DataPrivilegeDisplaySubId = {
    PrivilegeDataDisplaySubIdAllPartnerId: DataPrivilege.PrivilegeDataAllPartnerId.replace("privilege-data-", ""),
    PrivilegeDataDisplaySubIdOwnPartnerId: DataPrivilege.PrivilegeDataOwnPartnerId.replace("privilege-data-", "")
} as const;
export type DataPrivilegeDisplaySubId = typeof DataPrivilegeDisplaySubId[keyof typeof DataPrivilegeDisplaySubId];

/**
 * API権限情報
 */
export interface ApiPrivilegeInfo {

    /**
     * APIのPath
     */
    apiPath: string,
    /**
     * メソッド
     */
    method: string,
    /**
     * 機能用権限
     */
    functionPrivilege: FunctionPrivilegeInfo
}

/**
 * マスターグループ情報
 */
export interface GroupInfo extends PrivilegeCommonInfo<GroupData> { };

/**
 * マスターロール情報
 */
export interface RoleInfo extends PrivilegeCommonInfo<RoleData> { };

/**
 * マスター機能用権限情報
 */
export interface FunctionPrivilegeInfo extends PrivilegeInfo<FunctionPrivilegeData> { };

/**
 * マスターデータ用権限情報
 */
export interface DataPrivilegeInfo extends PrivilegeInfo<DataPrivilegeData> { };

/**
 * グループロール組み合わせ情報
 */
export interface GroupRoleInfo extends PrivilegeCommonInfo<GroupRoleData> { };

/**
 * ロール機能用権限組み合わせ情報
 */
export interface RoleFunctionPrivilegeInfo extends PrivilegeCommonInfo<RoleFunctionPrivilegeData> { };

/**
 * ロールデータ用権限組み合わせ情報
 */
export interface RoleDataPrivilegeInfo extends PrivilegeCommonInfo<RoleDataPrivilegeData> { };

/**
 * グループ機能用権限組み合わせ情報
 */
export interface GroupFunctionPrivilegeInfo {
    groupInfo: GroupInfo,
    functionPrivilegeInfo: FunctionPrivilegeInfo[]
}

/**
 * グループデータ用権限組み合わせ情報
 */
export interface GroupDataPrivilegeInfo {
    groupInfo: GroupInfo,
    dataPrivilegeInfo: DataPrivilegeInfo[]
}

/**
 * 権限テーブルカラム情報
 */
export interface PrivilegeCommonInfo<T extends PrivilegeCommonData> {
    /**
     * id
     */
    id: string,
    /**
     * subId
     */
    subId: string,
    /**
     * category
     */
    category: Category,
    /**
     * data
     */
    data: T,
}


/**
 * PrivilegeCommonInfoのdataの中身（共通）
 */
export interface PrivilegeCommonData {
    /**
     * displaySubId
     */
    displaySubId: string,
}


/**
 * PrivilegeCommonInfoのdataの中身（マスターグループ情報用）
 */
export interface GroupData extends PrivilegeCommonData {
    /**
     * sortNumber
     */
    sortNumber: number,
};

/**
 * PrivilegeCommonInfoのdataの中身（マスターロール情報用）
 */
export interface RoleData extends PrivilegeCommonData { };

/**
 * PrivilegeCommonInfoのdataの中身（マスター機能用権限情報）
 */
export interface FunctionPrivilegeData extends PrivilegeData { };

/**
 * PrivilegeCommonInfoのdataの中身（マスターデータ用権限情報）
 */
export interface DataPrivilegeData extends PrivilegeData { };

/**
 * PrivilegeCommonInfoのdataの中身（グループロール組み合わせ情報用）
 */
export interface GroupRoleData extends PrivilegeCommonData { };

/**
 * PrivilegeCommonInfoのdataの中身（ロール機能用権限組み合わせ情報用）
 */
export interface RoleFunctionPrivilegeData extends PrivilegeCommonData { };

/**
 * PrivilegeCommonInfoのdataの中身（ロールデータ用権限組み合わせ情報用）
 */
export interface RoleDataPrivilegeData extends PrivilegeCommonData { };


/**
 * マスター権限情報（機能、またはデータを指定）
 */
export interface PrivilegeInfo<T extends PrivilegeData> extends PrivilegeCommonInfo<T> { };

/**
 * PrivilegeCommonInfoのdataの中身（マスター権限情報用）
 */
export interface PrivilegeData extends PrivilegeCommonData {
    /**
     * sortNumber
     */
    sortNumber: number,
};

/**
 * Category
 */
export const Category = {
    groupGroup: "group-group",
    privilegePrivilege: "privilege-privilege",
    groupRole: "group-role",
    rolePrivilege: "role-privilege",
} as const;
export type Category = typeof Category[keyof typeof Category];
