import { BusinessPartnerInfo } from "../UserInfomation";

/**
 * ドローンの機材情報
 */
export interface DroneInfo {
    /**
     * ID
     */
    id: string,
    /**
     * ドローンが属するテナントID
     */
    tenantId: string,
    /**
     * ソート番号
     */
    sort: number,
    /**
     * 表示するラベル
     */
    label: string,
    /**
     * ドローン情報
     */
    kind: string,
    /**
     * ペイロード情報
     */
    payload: number,
    /**
     * 位置情報を送信しているスマートフォン
     * （アプリインスタンスID）
     */
    linkingSmartphone?: string,
    /**
     * 最大飛行時間
     */
    maxFlightMinutes: number,
    /**
     * 飛行までの準備時間
     */
    preparationMinutes: number,
    /**
     * 備考
     */
    remarks: string,
    /**
     * URL
     */
    img1: string,

    /**
     * ドローンの状態
     */
    status: DroneStatus,

    /**
     * ビジネスパートナーIDリスト
     */
    businessPartnerInfoList: BusinessPartnerInfo[],

    /**
     * 無人航空機の登録記号
     */
    registrationId: string,

    /**
     * 認証情報
     */
    certification?: DroneCertification,
    /**
     * 無人航空機の設計製造者
     */
    designManufacturer: string,
    /**
     * 製造番号
     */
    serialNumber: string
}

/**
 * ドローン認証情報
 */
export interface DroneCertification {
    /**
     * 認証種別
     */
    kind: CertificationKind,
    /**
     * 認証区分
     */
    type: CertificationType,
    /**
     * 認証番号
     */
    number: string
}

/**
 * 認証種別
 */
export const CertificationKind = {
    /**
     * 型式認証
     */
    Model: "型式認証",
    /**
     * 機体認証
     */
    Aircraft: "機体認証"
} as const;
export type CertificationKind = typeof CertificationKind[keyof typeof CertificationKind];

/**
 * 認証区分
 */
export const CertificationType = {
    /**
     * 一種
     */
    Tier1: "一種",
    /**
     * 二種
     */
    Tier2: "二種"
} as const;
export type CertificationType = typeof CertificationType[keyof typeof CertificationType];

/**
 * ドローンの性能
 */
export interface DroneSpec {
    /**
     * ペイロード（グラム）
     */
    payload: number,
    /**
     * 最大飛行時間
     */
    maxFlightMinutes: number,
    /**
     * 充電時間
     */
    chargeMinutes: number,
}

/**
 * 
 */
export const DroneStatus = {
    /**
     * ドローンが未割り当て
     */
    Empty: "Empty",
    /**
     * ドックで待機中
     */
    Docking: "Docking",
    /**
     * 受付中
     */
    Accepting: "Accepting",
    /**
     * 飛行指示待ち
     */
    Waiting: "Waiting",
    /**
     * 飛行準備中
     */
    Preparing: "Preparing",
    /**
     * 飛行中
     */
    Flighting: "Flighting",
    /**
     * 帰還中
     */
    Returning: "Returning",
    /**
     * 欠航
     */
    Canceled: "Canceled",
} as const;
export type DroneStatus = typeof DroneStatus[keyof typeof DroneStatus];

/**
 * ドローンデータの初期化データ
 */
export interface InitDroneInfo { 
    tenantId: string, 
    data: DroneInfo[] 
}

export interface DeleteDroneInfo { 
    tenantId: string, 
    Key: {
        id: string
    }[] 
}