import React from 'react';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { orange, blue } from '@mui/material/colors';
import { CssBaseline } from '@mui/material';
import OrderAuth from './pages/order/OrderAuth';
import OrderComplete from './pages/order/OrderComplete';
import OrderConfirm from './pages/order/OrderConfirm';
import OrderFlightSearchInput from './pages/order/OrderFlightSearchInput';
import OrderView from './pages/order/OrderView';
import RequesterView from './pages/requester/RequesterView';
import OrderGoodsAndDestinationInput from './pages/order/OrderGoodsAndDestinationInput';
import GeoInformationView from './pages/geoInformation/geoInformationView';
import Forbidden403Page from './pages/error/Forbidden403Page';
import PrivateRoute from './common/privateRoute';

/**
* Material UIのテーマを変える
*/
const theme = createTheme(({
  palette: {
    primary: blue,
    secondary: orange,
    background: {
      paper: "#fafafa",
      default: "#EFEFEF",
    },
  },
}));

let neutralMapName = process.env.REACT_APP_AWS_NEUTRAL_MAP_NAME as string
let saterllitemapName = process.env.REACT_APP_AWS_SATELLITE_MAP_NAME as string
// 既存のcognitoの設定
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID as string, // REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_AWS_REGION, // REQUIRED - Amazon Cognito Region
  },
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          neutralMapName: {
            // REQUIRED - Amazon Location Service Map resource name
            style: ["VectorHereExplore"] // REQUIRED - String representing the style of map resource
          },
          saterlltemapName: {
            style: ["RasterHereExploreSatellite"]
          }
        },
        neutral: neutralMapName, // REQUIRED - Amazon Location Service Map resource name to set as default
        satellite: saterllitemapName
      },
      region: process.env.REACT_APP_AWS_REGION, // REQUIRED - Amazon Location Service Region
    },
  }
});

const App = (props: any) => {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/*" element={<PrivateRoute />}>
              <Route path=':urlId' element={<OrderFlightSearchInput />} />
              <Route path='orderflightsearchinput/:urlId' element={<OrderFlightSearchInput />} />
              <Route path='ordergoodsanddestinationinput/:urlId' element={<OrderGoodsAndDestinationInput />} />
              <Route path='orderconfirm/:urlId' element={<OrderConfirm />} />
              <Route path='ordercomplete/:urlId' element={<OrderComplete />} />
            </Route>
            <Route path='/orderview' element={<OrderView />} />
            <Route path='/orderauth' element={<OrderAuth />} />
            <Route path='/requesterview' element={<RequesterView />} />
            <Route path='/geoinformationview' element={<GeoInformationView />} />
            <Route path='/forbidden403Page' element={<Forbidden403Page />}></Route>
          </Routes>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}
export default App;
